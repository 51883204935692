/**
* Generated automatically at built-time (2024-11-11T12:59:43.150Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-beerserver-olkyl",templateKey: "sites/88-3095b2e8-093c-41e7-85dc-aac120bdc700"};